import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ScaleLoader } from "react-spinners";
import LigthButton from "../../components/buttons/LigthButton";
import InputText from "../../components/input/Input";
import Context from "../../context/Context";
import Img_Recovery from "../../resources/img/recovery.jpg";
import { validateEmail } from "../../utils/Utils";

const Recovery = () => {
  const { getRecoveryPassword } = useContext(Context);
  const [t] = useTranslation("global");
  const [email, setEmail] = useState("");
  const notifyError = (text) => toast.error(text);
  const notifySuccess = (text) => toast.success(text);
  const [isEnableToSend, setIsEnableToSend] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsEnableToSend(true);
    }, 120000);
  }, [isEnableToSend]);

  const handleSendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsEnableToSend(false);
    if (!isEnableToSend) {
      setLoading(false);
      setIsEnableToSend(true);
      return notifyError(t("errors.disabled_form"));
    }
    if (!email) {
      setLoading(false);
      setIsEnableToSend(true);
      return notifyError(t("job.form.err_required"));
    }
    if (!validateEmail(email)) {
      setLoading(false);
      setIsEnableToSend(true);
      return notifyError(t("errors.email"));
    }
    const res = await getRecoveryPassword({
      email,
      host: window.location.origin,
      lng: window.localStorage.getItem("lng") ?? "en",
    });
    if (res?.error) {
      setLoading(false);
      setIsEnableToSend(true);
      return notifyError(t(`errors.${res.error}`));
    }
    notifySuccess(t("recovery.txt_email_sent"), { duration: 10000 });
    setIsEnableToSend(false);
    setLoading(false);
  };

  return (
    <div className="bg-slate-100">
      <div className=" bg-white container px-4 mx-auto flex justify-center pt-28 h-screen">
        <div className=" flex flex-col gap-4 md:max-w-md">
          <div>
            <h2 className="font-bold text-blue-500 text-2xl ">
              {t("recovery.title_recovery")}
            </h2>
            <p>{t("recovery.subtitle_recovery")}</p>
          </div>
          <form onSubmit={handleSendEmail} className="grid gap-3">
            <div>
              <InputText
                textLabel={t("job.form.txt_email") + "*"}
                placeholder={t("job.form.txt_email")}
                onChange={(e) => setEmail(e.target.value)}
                name={"email"}
                value={email}
              />
            </div>
            {isEnableToSend && (
              <div>
                <LigthButton
                  onClick={handleSendEmail}
                  type={"submit"}
                  title={t("recovery.btn_send_email")}
                  disabled={!isEnableToSend}
                />
              </div>
            )}
            <div className="flex justify-center">
              <ScaleLoader color={"#1967D2"} size={100} loading={loading} />
            </div>
          </form>
          {!isEnableToSend && (
            <p className="text-slate-500">{t("recovery.not_recived_email")}</p>
          )}
          <div className="flex justify-center">
            <img src={Img_Recovery} className="w-96" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recovery;
