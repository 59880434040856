import React, { useReducer, useEffect } from "react";
import {
  handleAppliedJobs,
  handleLogin,
  handleRegister,
  handleIsAuth,
  handleApplyJob,
  handleJobByReference,
  handleUpdateUser,
  handleJobsByParams,
  handleUpdatePassword,
  handleRecoveryPassword,
  handleChangePassword,
} from "../api/request.api";
import Context from "./Context";
import Reducer from "./Reducer";
import {
  CLOSE_SESION,
  GET_COMPANY,
  GET_JOBS,
  GET_JOB_BY_NUM_REFERENCE,
  GET_APPLIED_JOBS,
  POST_SIGNIN,
  POST_SIGNUP,
  GET_EXTRAS,
} from "./Types";

export const UrlEnv = process.env.REACT_APP_API_URL;

const AppProvider = (props) => {
  const initialState = {
    sesion: null,
    user: [],
    jobs: [],
    company: [],
    selectedJob: [],
    appliedJobs: [],
    extras: {},
  };
  const [state, dispatch] = useReducer(Reducer, initialState);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data) {
      if (!data?.user) {
        return localStorage.removeItem("user");
      }
      dispatch({ type: POST_SIGNIN, payload: data });
      verifyAuth(data.token);
    } else {
      initialState.sesion = false;
    }
  }, []);

  useEffect(() => {
    if (state.user.user) {
      getAppliedJobs();
    }
  }, [state.user]);

  const verifyAuth = (token) => {
    try {
      if (token) {
        handleIsAuth(token)
          .then((response) => {
            if (response.data.message !== "isAuth") {
              localStorage.removeItem("user");
              dispatch({ type: CLOSE_SESION });
              window.location.reload();
            }
          })
          .catch((err) => {
            console.error(err);
            handleCloseSesion();
          });
      }
    } catch (error) {
      console.log(error);
      handleCloseSesion();
    }
  };

  const getRegister = async (body) => {
    try {
      const res = handleRegister(body)
        .then((response) => {
          localStorage.setItem("user", JSON.stringify(response.data));
          dispatch({ type: POST_SIGNUP, payload: response.data });

          if (window.location.pathname === "/register") {
            const url = window.localStorage.getItem("route");
            if (url) {
              window.location.replace(url);
            }
          }
          return response;
        })
        .catch((err) => {
          return err.response.data;
        });
      return res;
    } catch (err) {
      console.log("Ocurrio un error inesperado: ", err);
      return err.response.data;
    }
  };

  const getLogin = async (body) => {
    try {
      const res = await handleLogin(body)
        .then((response) => {
          dispatch({ type: POST_SIGNIN, payload: response.data });
          localStorage.setItem("user", JSON.stringify(response.data));

          if (window.location.pathname === "/login") {
            const url = window.localStorage.getItem("route");
            if (url) {
              window.location.replace(url);
            }
          }
          return response;
        })
        .catch((err) => {
          console.error(err);
          return err.response.data;
        });
      return res;
    } catch (err) {
      console.log("Ocurrio un error inesperado: ", err);
      return err.response.data;
    }
  };

  const getUpdateUser = async (body) => {
    try {
      const res = await handleUpdateUser(
        state.user.token,
        body,
        state.user.user.id
      )
        .then((response) => {
          localStorage.setItem("user", JSON.stringify(response.data));
          dispatch({ type: POST_SIGNUP, payload: response.data });
          return response;
        })
        .catch((err) => {
          console.error(err);
          return err.response.data;
        });
      return res;
    } catch (err) {
      console.log("Ocurrio un error inesperado: ", err);
      return err.response.data;
    }
  };

  const getUpdatePassword = async (body) => {
    try {
      const res = await handleUpdatePassword(
        state.user.token,
        body,
        state.user.user.id
      )
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
      return res;
    } catch (err) {
      console.log("Ocurrio un error inesperado: ", err);
      return err.response.data;
    }
  };

  const getRecoveryPassword = async (body) => {
    try {
      const res = await handleRecoveryPassword(body)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
      return res;
    } catch (err) {
      console.log("Ocurrio un error inesperado: ", err);
      return err.response.data;
    }
  };

  const getChangePassword = async (token, body) => {
    try {
      const res = await handleChangePassword(token, body)
        .then((response) => {
          localStorage.setItem("user", JSON.stringify(response.data));
          dispatch({ type: POST_SIGNUP, payload: response.data });
          return response;
        })
        .catch((err) => {
          return err.response.data;
        });
      return res;
    } catch (err) {
      console.log("Ocurrio un error inesperado: ", err);
      return err.response.data;
    }
  };

  const getJobsByParams = async (params) => {
    try {
      const res = handleJobsByParams(params)
        .then((response) => {
          dispatch({ type: GET_JOBS, payload: response.data.jobs });
          dispatch({ type: GET_COMPANY, payload: response.data.company[0] });
          const extras = {
            allCategories: response.data.AllCategories,
            allSchedules: response.data.AllSchedules,
            page: response.data.page,
            recordsPerPage: response.data.records_per_page,
            totalPages: response.data.total_pages,
            totalRecords: response.data.total_records,
          };
          dispatch({ type: GET_EXTRAS, payload: extras });
          return response;
        })
        .catch((err) => {
          console.error(err);
          return err.response.data;
        });

      return res;
    } catch (err) {
      console.log("Ocurrio un error inesperado: ", err);
      return err.response.data;
    }
  };

  const getJobByReference = async (reference) => {
    try {
      const res = await handleJobByReference(reference.company, reference.id);

      dispatch({ type: GET_JOB_BY_NUM_REFERENCE, payload: res.data.job });
      dispatch({ type: GET_COMPANY, payload: res.data.company });
    } catch (err) {
      console.log("Ocurrio un error inesperado: ", err);
      return err.response.data;
    }
  };

  const getApplyJob = (body) => {
    try {
      const data = handleApplyJob(state.user.token, body)
        .then((response) => {
          getAppliedJobs();
        })
        .catch((err) => {
          console.error(err);
        });

      return data;
    } catch (err) {
      console.log("Ocurrio un error inesperado: ", err);
      return err.response.data;
    }
  };

  const getAppliedJobs = () => {
    try {
      handleAppliedJobs(state.user.token, state.user.user.id)
        .then((response) => {
          dispatch({ type: GET_APPLIED_JOBS, payload: response.data });
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.log("Ocurrio un error inesperado: ", err);
      return err.response.data;
    }
  };

  const handleCloseSesion = () => {
    localStorage.removeItem("user");
    dispatch({ type: CLOSE_SESION });
    window.location.reload();
  };

  return (
    <Context.Provider
      value={{
        getRegister,
        getLogin,
        getJobsByParams,
        getJobByReference,
        handleCloseSesion,
        getApplyJob,
        getAppliedJobs,
        getUpdateUser,
        getUpdatePassword,
        getRecoveryPassword,
        getChangePassword,
        dispatch,
        company: state.company,
        user: state.user,
        jobs: state.jobs,
        selectedJob: state.selectedJob,
        appliedJobs: state.appliedJobs,
        extras: state.extras,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default AppProvider;
