import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import RegisterForm from "../../components/auth/RegisterForm";
import LigthButton from "../../components/buttons/LigthButton";
import img from "../../resources/img/join.png";
import Context from "../../context/Context";
import { useNavigate } from "react-router-dom";
import { validateEmail, validateNumberPhone } from "../../utils/Utils";
import { toast } from "react-hot-toast";
import { ScaleLoader } from "react-spinners";

const Register = () => {
  const [t] = useTranslation("global");
  const { getRegister } = useContext(Context);
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [resumeFile, setResumeFile] = useState("");
  const [linkedinURL, setLinkedinURL] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPass, setRepeatPass] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const notifyError = (text) => toast.error(text);

  const onRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      !firstName ||
      !lastName ||
      !country ||
      !address ||
      !phoneNumber ||
      !email ||
      !password ||
      !resumeFile
    ) {
      notifyError(t("job.form.err_required"));
      setIsLoading(false);
      return;
    }
    if (!validateEmail(email)) {
      notifyError(t("errors.email"));
      setIsLoading(false);
      return;
    }
    if (!validateNumberPhone(phoneNumber)) {
      notifyError(t("errors.phone"));
      setIsLoading(false);
      return;
    }
    if (password.length <= 7) {
      notifyError(t("errors.password_size"));
      setIsLoading(false);
      return;
    }
    if (password !== repeatPass) {
      notifyError(t("errors.password"));
      setIsLoading(false);
      return;
    }

    const body = new FormData();

    body.append("first_name", firstName);
    body.append("last_name", lastName);
    body.append("country", country);
    body.append("address", address);
    body.append("phone_number", phoneNumber);
    body.append("email", email);
    body.append("password", password);
    body.append("resume_file", resumeFile);
    body.append("linkedin_url", linkedinURL);

    const res = await getRegister(body);
    if (res?.error) {
      setIsLoading(false);
      return notifyError(t(`errors.${res.error}`));
    }
    setIsLoading(false);
  };

  return (
    <div className="container mx-auto md:grid md:grid-cols-3 lg:flex lg:justify-center md:pt-24 gap-4 md:gap-8">
      <div className="md:max-w-lg flex flex-col items-center pt-24 md:pt-10 px-4">
        <div className="pb-2">
          <p className="font-bold text-2xl">{t("job.form.txt_signUp")}</p>
          <p className="text-slate-600">{t("job.form.subtitle")}</p>
        </div>
        <img
          className="hidden md:block"
          style={{ maxWidth: 350 }}
          src={img}
          alt="join.png"
        />
      </div>
      <div className="grid col-span-2 items-center border-4 border-blue-light rounded p-8">
        <form onSubmit={onRegister}>
          <RegisterForm
            t={t}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            country={country}
            setCountry={setCountry}
            address={address}
            setAddress={setAddress}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            email={email}
            setEmail={setEmail}
            resumeFile={resumeFile}
            setResumeFile={setResumeFile}
            linkedinURL={linkedinURL}
            setLinkedinURL={setLinkedinURL}
            password={password}
            setPassword={setPassword}
            repeatPass={repeatPass}
            setRepeatPass={setRepeatPass}
          />
          {!isLoading ? (
            <LigthButton title={t("job.form.btn_register")} type={"submit"} />
          ) : (
            <div className="flex justify-center">
              <ScaleLoader color={"#1967D2"} size={100} loading={isLoading} />
            </div>
          )}
          <div className="py-2 flex justify-center gap-2 text-center">
            <p>{t("job.form.isLogin")}</p>
            <p
              onClick={() => {
                navigate("/login");
              }}
              className="text-blue-regular font-bold cursor-pointer"
            >
              {t("job.form.btn_isLogin")}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
