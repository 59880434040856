import {
  POST_SIGNIN,
  POST_SIGNUP,
  GET_JOBS,
  GET_JOB_BY_NUM_REFERENCE,
  GET_COMPANY,
  CLOSE_SESION,
  POST_APPLY_JOB,
  GET_APPLIED_JOBS,
  GET_EXTRAS,
} from "./Types";

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case POST_SIGNIN:
      return {
        ...state,
        user: payload,
      };
    case POST_SIGNUP:
      return {
        ...state,
        user: payload,
      };
    case GET_JOBS:
      return {
        ...state,
        jobs: payload,
      };
    case GET_COMPANY:
      return {
        ...state,
        company: payload,
      };
    case GET_EXTRAS:
      return {
        ...state,
        extras: payload,
      };
    case GET_JOB_BY_NUM_REFERENCE:
      return {
        ...state,
        selectedJob: payload,
      };
    case GET_APPLIED_JOBS:
      return {
        ...state,
        appliedJobs: payload,
      };
    case POST_APPLY_JOB:
      return {
        ...state,
        appliedJobs: payload,
      };
    case CLOSE_SESION:
      return {
        ...state,
        user: [],
      };
    default:
      return state;
  }
};
