import { FaEye, FaEyeSlash } from "react-icons/fa";

const InputPassword = ({
  textLabel,
  name,
  showPassword,
  setShowPassword,
  onChange,
  value,
  placeholder,
}) => {
  return (
    <div className="py-2 flex flex-col">
      <label className="font-bold" htmlFor={name}>
        {textLabel}
      </label>
      <div className="relative">
        <input
          value={value}
          onChange={onChange}
          type={showPassword ? "text" : "password"}
          id={name}
          placeholder={placeholder}
          className="h-10 drop-shadow-md rounded-md p-4 w-full"
        />
        <div className="absolute top-3 right-5">
          {showPassword ? (
            <FaEyeSlash
              size={20}
              onClick={() => setShowPassword(!showPassword)}
            />
          ) : (
            <FaEye size={20} onClick={() => setShowPassword(!showPassword)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default InputPassword;
