import axios from "axios";

export const urlEnv = process.env.REACT_APP_API_URL;

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const handleRegister = async (body) => {
  config.headers["Content-Type"] = "multipart/form-data";
  return await axios.post(urlEnv + "auth/signUp", body, config);
};

export const handleLogin = async (body) =>
  await axios.post(urlEnv + "auth/signIn", body, config);

export const handleRecoveryPassword = async (body) => {
  return await axios.post(urlEnv + "auth/recoveryPassword", body, config);
};

export const handleChangePassword = async (token, body) => {
  config.headers["x-access-token"] = token;
  return await axios.patch(urlEnv + "auth/changePassword", body, config);
};

export const handleUpdateUser = async (token, body, id) => {
  config.headers["x-access-token"] = token;
  config.headers["Content-Type"] = "multipart/form-data";
  return await axios.patch(`${urlEnv}user/${id}`, body, config);
};

export const handleUpdatePassword = async (token, body, id) => {
  config.headers["x-access-token"] = token;
  config.headers["Content-Type"] = "multipart/form-data";
  return await axios.patch(`${urlEnv}user/updatePassword/${id}`, body, config);
};

export const handleIsAuth = (token) => {
  config.headers["x-access-token"] = token;
  return axios.get(urlEnv + "auth/pingAuth", config);
};

export const handleJobsByParams = (params) => {
  return axios.get(`${urlEnv}jobs/company/${params}`, config);
};

export const handleJobByReference = (company, id) => {
  return axios.get(`${urlEnv}jobs/company/${company}/${id}`, config);
};

export const handleAppliedJobs = (token, id) => {
  config.headers["x-access-token"] = token;
  return axios.get(`${urlEnv}application/user/${id}`, config);
};

export const handleApplyJob = (token, body) => {
  config.headers["x-access-token"] = token;
  return axios.post(`${urlEnv}application`, body, config);
};
