import React, { useRef } from "react";
import { CgSoftwareUpload } from "react-icons/cg";
import RegularButton from "../buttons/RegularButton";

const InputText = ({
  textLabel,
  onChange,
  value,
  name,
  type,
  maxLength,
  minLength,
  placeholder,
}) => {
  return (
    <div className="py-2 flex flex-col">
      <label className="font-bold" htmlFor={name}>
        {textLabel}
      </label>
      <input
        value={value}
        onChange={onChange}
        id={name}
        name={name}
        type={type ? type : "text"}
        maxLength={maxLength}
        minLength={minLength}
        placeholder={placeholder}
        className="h-10 drop-shadow-md rounded-md p-4"
      />
    </div>
  );
};

export default InputText;

export const InputFile = ({ textLabel, onChange, value, name }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  return (
    <div className="py-4 flex flex-col">
      <label className="font-bold" htmlFor={name}>
        {textLabel}
      </label>
      <div>
        <RegularButton
          title={value ? value.name : "Upload file"}
          useIcon={true}
          icon={<CgSoftwareUpload size={24} />}
          onClick={handleClick}
        />
        <input
          className="hidden"
          type={"file"}
          onChange={onChange}
          ref={hiddenFileInput}
          accept=".jpeg,.png,.jpg,.pdf"
        />
      </div>
    </div>
  );
};
