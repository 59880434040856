import React, { useContext, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Context from "../context/Context";
import { Loading } from "../components/loading/Loading";
import Auth from "../pages/auth/Auth";
import Register from "../pages/auth/Register";
import Recovery from "../pages/auth/Recovery";
import NotFound from "../pages/notFound/NotFound";
import ChangePassword from "../pages/profile/ChangePassword";
const Navbar = lazy(() => import("../components/navbar/Navbar"));
const LandingPage = lazy(() => import("../pages/landing/LandingPage"));
const JobDetails = lazy(() => import("../pages/jobs/JobDetails"));
const Profile = lazy(() => import("../pages/profile/Profile"));

const AppRouter = () => {
  const { user } = useContext(Context);
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <Navigate to={user?.user ? "/profile" : "/login"} replace />
            }
          />
          <Route path="/change-password" element={<ChangePassword />} />
          {user.length <= 0 ? (
            <Route path="/recovery-password" element={<Recovery />} />
          ) : null}
          {user.length <= 0 ? <Route path="/login" element={<Auth />} /> : null}
          {user.length <= 0 ? (
            <Route path="/register" element={<Register />} />
          ) : null}
          {user?.user ? <Route path="/profile" element={<Profile />} /> : null}
          <Route path="/:slug/" element={<LandingPage />} />
          <Route path="/:slug/job/:id" element={<JobDetails />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default AppRouter;
