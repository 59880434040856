import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import LigthButton from "../../components/buttons/LigthButton";
import InputPassword from "../../components/input/InputPassword";
import Context from "../../context/Context";

const ChangePassword = () => {
  const { getChangePassword } = useContext(Context);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassowrd, setRepeatNewPassowrd] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const notifyError = (text) => toast.error(text);
  const notifySuccess = (text) => toast.success(text);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!newPassword || !repeatNewPassowrd) {
      setLoading(false);
      return notifyError(t("job.form.err_required"));
    }
    if (newPassword.length <= 7) {
      setLoading(false);
      return notifyError(t("errors.password_size"));
    }
    if (newPassword !== repeatNewPassowrd) {
      setLoading(false);
      return notifyError(t("recovery.txt_not_match"));
    }

    const res = await getChangePassword(searchParams.get("token"), {
      password: newPassword,
    });

    if (res?.error) {
      setLoading(false);
      return notifyError(t(`errors.${res.error}`));
    }

    notifySuccess(t("recovery.msg_password_changed"));
    setLoading(false);
    setTimeout(() => {
      navigate("/profile");
    }, 2000);
  };

  return (
    <div className="bg-slate-100">
      <div className=" bg-white container px-4 mx-auto flex justify-center pt-28 h-screen">
        <div className=" flex flex-col gap-4">
          <div>
            <h2 className="font-bold text-blue-500 text-2xl ">
              {t("recovery.txt_title_change_password")}
            </h2>
          </div>
          <form onSubmit={handleChangePassword} className="grid gap-3">
            <div>
              <InputPassword
                textLabel={t("recovery.inp_new_password") + "*"}
                placeholder={"*************"}
                onChange={(e) => setNewPassword(e.target.value)}
                name={"Nueva contraseña"}
                value={newPassword}
                setShowPassword={setShowPassword}
                showPassword={showPassword}
              />
            </div>
            <div>
              <InputPassword
                textLabel={t("recovery.inp_repeat_password") + "*"}
                placeholder={"*************"}
                onChange={(e) => setRepeatNewPassowrd(e.target.value)}
                name={"Repetir contraseña"}
                value={repeatNewPassowrd}
                setShowPassword={setShowPassword}
                showPassword={showPassword}
              />
            </div>
            {!loading ? (
              <div>
                <LigthButton
                  onClick={handleChangePassword}
                  type={"submit"}
                  title={t("recovery.btn_change_password")}
                />
              </div>
            ) : (
              <div className="flex justify-center">
                <ScaleLoader color={"#1967D2"} size={100} loading={loading} />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
