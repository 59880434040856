import React from "react";

const RegularButton = ({
  title,
  bgColor,
  textColor,
  onClick,
  useIcon = false,
  icon,
  type,
}) => {
  return (
    <div
      onClick={onClick}
      type={type ?? "submit"}
      className={`whitespace-nowrap flex items-center justify-center gap-2 cursor-pointer text-center py-2 px-6 font-bold rounded-lg w-full
        ${bgColor ? bgColor : "bg-blue-regular"} ${
        textColor ? textColor : "text-white"
      } hover:scale-105 transition-transform delay-150`}
    >
      {title}
      {useIcon ? icon : null}
    </div>
  );
};

export default RegularButton;
