import { FaSortAlphaDown, FaSortAlphaUp, FaSortNumericDown } from "react-icons/fa";

export const parseDate = (date) => {
  return date.substring(0, 10).split("-").reverse().join("/");
};

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return re.test(email);
}

export function validateNumberPhone(number) {
  const re = /^[\(\)\+\ \-0-9]{6,20}$/g; // eslint-disable-line
  const result = re.test(number);
  return result;
}

export const urlBaseParams = (params = {}) => {
  let result = ""
  const keys = Object.keys(params)
  const values = Object.values(params)

  for (const [key, value] of Object.entries(params)) {
    if (value) {
      result += `${key}=${value}${(value === values[keys.length - 1]) ? "" : '&'}`
    }
  }
  return params?.page ? result : result.substring(0, (result.length - 1));
};


export const typeSort = [
  <div className="whitespace-nowrap flex flex-nowrap items-center gap-2">
    <FaSortNumericDown />
    {"Posted date"}
  </div>,
  <div className="whitespace-nowrap flex flex-nowrap items-center gap-2">
    <FaSortAlphaDown />
    {" A - Z"}
  </div>,
  <div className="whitespace-nowrap flex flex-nowrap items-center gap-2">
    <FaSortAlphaUp />
    {" Z - A"}
  </div>,
];

export const sorts = ["date", "asc", "desc"];