import React from "react";
import { useState } from "react";
import InputText from "../input/Input";
import InputPassword from "../input/InputPassword";

const LoginForm = ({ t, email, setEmail, password, setPassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <InputText
        placeholder="user@example.com"
        value={email}
        name="email"
        textLabel={t("job.form.txt_email") + "*"}
        onChange={(e) => setEmail(e.target.value)}
      />
      <InputPassword
        placeholder={"**********"}
        name={"password"}
        textLabel={t("job.form.txt_password") + "*"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        setShowPassword={setShowPassword}
        showPassword={showPassword}
      />
    </div>
  );
};

export default LoginForm;
