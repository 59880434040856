import React from "react";
import IMG_NotFound from "../../resources/img/NotFound.jpg";
const NotFound = () => {
  return (
    <div>
      <div className="container mx-auto flex flex-col justify-center items-center min-h-full py-28">
        <h3 className="text-2xl md:text-4xl font-bold">Woops!</h3>
        <p>Something went wrong</p>
        <img
          className="w-3/4 max-w-xl"
          src={IMG_NotFound}
          alt="not-found.jpg"
        />
        <h4 className="text-2xl md:text-4xl font-bold">404 Page not found</h4>
      </div>
    </div>
  );
};

export default NotFound;
