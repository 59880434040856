import { useState } from "react";
import InputText, { InputFile } from "../input/Input";
import InputPassword from "../input/InputPassword";

const RegisterForm = ({
  t,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  country,
  setCountry,
  address,
  setAddress,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  resumeFile,
  setResumeFile,
  linkedinURL,
  setLinkedinURL,
  password,
  setPassword,
  repeatPass,
  setRepeatPass,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <div className="md:grid md:grid-cols-2 md:gap-4">
        <InputText
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          name="firstName"
          textLabel={t("job.form.txt_first_name") + "*"}
          placeholder="William"
        />
        <InputText
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          name="lastName"
          textLabel={t("job.form.txt_last_name") + "*"}
          placeholder="Jhonson"
        />
      </div>
      <div className="md:grid md:grid-cols-2 md:gap-4">
        <InputText
          textLabel={t("job.form.txt_country") + "*"}
          value={country}
          name="country"
          onChange={(e) => setCountry(e.target.value)}
          placeholder="México"
        />
        <InputText
          value={address}
          name="address"
          textLabel={t("job.form.txt_address") + "*"}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Some Place #321 CP 10001"
        />
      </div>
      <div className="md:grid md:grid-cols-2 md:gap-4">
        <InputText
          value={phoneNumber}
          name="phoneNumber"
          textLabel={t("job.form.txt_phone") + "*"}
          onChange={(e) => setPhoneNumber(e.target.value)}
          maxLength={16}
          minLength={6}
          type={"tel"}
          placeholder={"+1 123 123 1234"}
        />
        <InputText
          value={email}
          name="email"
          type={"email"}
          textLabel={t("job.form.txt_email") + "*"}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="user@example.com"
        />
      </div>
      <div className="md:grid md:grid-cols-2 md:gap-4">
        <InputPassword
          placeholder={"**********"}
          name={"password"}
          textLabel={t("job.form.txt_password") + "*"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          setShowPassword={setShowPassword}
          showPassword={showPassword}
        />
        <InputPassword
          placeholder={"**********"}
          name={"repeatPassword"}
          textLabel={t("job.form.txt_r_password") + "*"}
          value={repeatPass}
          onChange={(e) => setRepeatPass(e.target.value)}
          setShowPassword={setShowPassword}
          showPassword={showPassword}
        />
      </div>
      <InputText
        value={linkedinURL}
        name="linkedinURL"
        textLabel={t("job.form.txt_linkedin")}
        onChange={(e) => setLinkedinURL(e.target.value)}
        placeholder="https://www.linkedin.com/in/user"
      />
      <InputFile
        name={"resumeFile"}
        onChange={(e) => setResumeFile(e.target.files[0])}
        value={resumeFile}
        textLabel={t("job.form.txt_resume") + "*"}
      />
    </div>
  );
};

export default RegisterForm;
