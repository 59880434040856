import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

import LoginForm from "../../components/auth/LoginForm";
import LigthButton from "../../components/buttons/LigthButton";
import img from "../../resources/img/join.png";
import Context from "../../context/Context";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";

const Auth = () => {
  const [t] = useTranslation("global");
  const { getLogin } = useContext(Context);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const notifyError = (text) => toast.error(text);

  const onLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!email || !password) {
      notifyError(t("job.form.err_required"));
      setIsLoading(false);
      return;
    }
    let body = { email, password };

    const res = await getLogin(body);
    if (res?.error) {
      setIsLoading(false);
      return notifyError(t(`errors.${res.error}`));
    }
    setIsLoading(false);
  };
  return (
    <div className="container px-4 lg:mx-auto md:grid md:grid-cols-2 lg:flex lg:justify-center md:pt-24 gap-4 md:gap-8">
      <div className="md:max-w-lg flex flex-col items-center pt-24 md:pt-10 md:mx-0">
        <div className="pb-2">
          <p className="font-bold text-2xl">{t("job.form.btn_isLogin")}</p>
          <p className="text-slate-600">{t("job.form.txt_sub_login")}</p>
        </div>
        <img
          className="hidden md:block"
          style={{ maxWidth: 350 }}
          src={img}
          alt="join.png"
        />
      </div>
      <div className="grid items-center border-4 border-blue-light rounded p-8">
        <form onSubmit={onLogin} className="lg:w-96">
          <LoginForm
            t={t}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
          />
          {!isLoading ? (
            <div className="pt-4">
              <LigthButton title={t("job.form.btn_login")} type={"submit"} />
            </div>
          ) : (
            <div className="flex justify-center">
              <ScaleLoader color={"#1967D2"} size={100} loading={isLoading} />
            </div>
          )}
          <div className="py-2 flex justify-center gap-2 text-center">
            <p>{t("job.form.isRegister")}</p>
            <p
              onClick={() => {
                navigate("/register");
              }}
              className="text-blue-regular font-bold cursor-pointer"
            >
              {t("job.form.btn_isRegister")}
            </p>
          </div>
          <div className="py-2 flex justify-center gap-2 text-center">
            <p
              className="hover:text-blue-500 cursor-pointer"
              onClick={() => {
                navigate("/recovery-password");
              }}
            >
              {t("recovery.btn_forget_password")}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Auth;
