import { ScaleLoader } from "react-spinners";

export const Loading = () => {
  return (
    <div
      style={{
        textAling: "center",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <ScaleLoader color={"#1967D2"} size={100} loading={true} />
    </div>
  );
};
