import { useEffect } from "react";
import AppRouter from "./routes/AppRouter";
import AppProvider from "./context/AppProvider";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import global_en from "./translations/en/global.json";
import global_es from "./translations/es/global.json";
import global_pb from "./translations/pb/global.json";
import { Toaster } from "react-hot-toast";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    es: {
      global: global_es,
    },
    pb: {
      global: global_pb,
    },
  },
});
function App() {
  useEffect(() => {
    const lng = localStorage.getItem("lng");
    if (lng) {
      i18next.changeLanguage(lng);
    }
  }, []);

  return (
    <AppProvider>
      <I18nextProvider i18n={i18next}>
        <AppRouter />
        <Toaster reverseOrder={false} />
      </I18nextProvider>
    </AppProvider>
  );
}

export default App;
