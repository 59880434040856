import React from "react";

const LigthButton = ({
  title,
  bgColor,
  textColor,
  bgHover,
  onClick,
  useIcon = false,
  icon,
  type,
  disabled = false,
}) => {
  return (
    <button
      onClick={onClick}
      type={type ?? "submit"}
      disabled={disabled}
      className={`whitespace-nowrap flex justify-center items-center gap-2 cursor-pointer text-center py-2 px-6 font-bold rounded-lg w-full
      ${bgColor ? bgColor : "bg-blue-light"} ${
        textColor ? textColor : "text-blue-regular"
      }
      ${bgHover ? bgHover : "hover:bg-blue-regular"}
       hover:text-white delay-75 transition-all ease-in-out
      `}
    >
      {title}
      {useIcon ? icon : null}
    </button>
  );
};

export default LigthButton;
